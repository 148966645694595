import LoadingScreen from 'Components/LoadingScreen';
import useAuth from 'hooks/useAuth';
import React, { FC, useEffect } from 'react';
import { getCompany } from 'slices/Company/companySlice';
import { getYearOptions } from 'slices/Company/yearSlice';
import { RootState, useDispatch, useSelector } from 'store';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

const CompanyGuard:FC<{ children: React.ReactNode }> = (
  { children }: { children: React.ReactNode },
) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const classes = useStyles();
  const company = useSelector((state: RootState) => state.company);
  const isLoaded = !!company.id;

  useEffect(() => {
    if (!user?.companyId) return;
    dispatch(getCompany(user?.companyId));
    dispatch(getYearOptions(user.companyId));
  }, [dispatch]);

  if (!isLoaded) {
    return <LoadingScreen />;
  }

  return (
    <div
      className={classes.root}
    >
      {children}
    </div>
  );
};

export default CompanyGuard;
