import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Grid,
  Badge,
  Button,
  Tooltip,
  Typography,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    cursor: 'pointer',
  },
  navLink: {
    fontSize: '1.125rem',
    textAlign: 'right',
    marginLeft: theme.spacing(3),
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const LoginButton: FC = () => {
  const { user } = useAuth();
  const classes = useStyles();
  const { logout } = useAuth();
  const history = useHistory();
  const { t } = useTranslation(['main']);
  const { pathname } = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/');
    } catch (error) {
      console.error('error signing out: ', error);
    }
  };

  const shouldHideUsername = !pathname.startsWith('/company') && !pathname.startsWith('/admin') && !pathname.startsWith('/reporting');

  return (
    <div>
      {!user ? (
        <>
          {/*
          <Link
              component={NavLink}
              to="/login"
              color="inherit"
              className={classes.navLink}
            >
              {t('accounts.login')}
            </Link>
        */}
        </>
      ) : (
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={0}
          >
              {!user.passwordChallenge && !shouldHideUsername && (
                <Grid item>
                <Typography>{user?.username}</Typography>
                </Grid>
              )}
              {user.passwordChallenge && !shouldHideUsername && (
                <Grid item>
                <Tooltip
                  className={classes.tooltip}
                  title={t('accounts.changePasswordTitle') || ''}
                  placement="top"
                  onClick={() => history.push('/account/changePassword')}
                >
                  <Badge
                    badgeContent="!"
                    color="error"
                  >
                    <Typography>{user?.username}</Typography>
                  </Badge>
                </Tooltip>
                </Grid>
              )}
            <Grid 
            item 
            marginLeft={2}
            >
              <Button
                onClick={handleLogout}
              >
                {t('accounts.logout')}
              </Button>
            </Grid>
          </Grid>
        </>
      ) }
    </div>
  );
};

export default LoginButton;
