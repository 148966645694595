import React, {
  FC,
} from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material';
import iconLabelFi from '../../energiateollisuus_violetti_rgb.svg';
import iconLabelEn from 'finnish-energy_purple.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: '100%',
  },
  textPanel: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface LogoProps {
  width?: number;
  height?: number;
}

const Logo: FC<LogoProps> = (props) => {
  const { width, height } = props;
  const classes = useStyles();
  const { i18n } = useTranslation('main');

  const getSource = () => {
    switch (i18n.language) {
      case ('sv'):
        return iconLabelFi;
      case ('en'):
        return iconLabelEn;
      default:
        return iconLabelFi;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.textPanel}>
        <img
          width={width ?? 127.267}
          height={height ?? 30}
          src={getSource()}
          alt="label"
        />
      </div>
    </div>
  );
};

export default Logo;