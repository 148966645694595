import {
  Action,
  createSlice,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit';
import {
  Company,
} from 'types/companyData';
import type { RootState } from 'store';
import { gql } from 'urql';
import { useClient } from 'graphql/client';
import _ from 'lodash';

const initialState: {
  yearOptions: number[],
  selectedYear: string,
} = {
  yearOptions: [],
  selectedYear: '',
};

const yearSlice = createSlice({
  name: 'years',
  initialState,
  reducers: {
    updateYears(state, action: PayloadAction<{ newYears: number[] }>) {
      const { newYears } = action.payload;
      const newState = state;
      newState.yearOptions = newYears;
    },
    updateSelectedYear(state, action: PayloadAction<{ selectedYear: string }>) {
      const { selectedYear } = action.payload;
      const newState = state;
      newState.selectedYear = selectedYear;
    },
    autoselectYear(state, action: PayloadAction<{ selectedYear: string }>) {
      const { selectedYear } = action.payload;
      if (state.selectedYear === '') {
        const newState = state;
        newState.selectedYear = selectedYear;
      }
    },
  },
});

const GET_YEARS_QUERY = gql`
  query GetYears($id: ID!) {
    company(id: $id) {
      networks(all: true) {
        year
      }
    }
  }
`;

export const getYearOptions = (companyId: string): ThunkAction<
void, RootState, unknown, Action<string>
> => async (dispatch) => {
  const result = await useClient()
    .query<{ company: Company }>(
    GET_YEARS_QUERY, { id: companyId }, { requestPolicy: 'network-only' },
  )
    .toPromise();

  const { error, data } = result;
  if (error) console.error(result.error);
  if (data && data.company) {
    const years = _.uniq(data.company.networks.map((x) => x.year)).sort();

    // TODO: Let user to decide the starting year
    // If no previous years exist, add data for this year only.
    if (years.length === 0) {
      const currentYear = new Date().getUTCFullYear();
      dispatch(yearSlice.actions.updateYears({ newYears: [currentYear] }));
      dispatch(yearSlice.actions.autoselectYear({ selectedYear: String(currentYear) }));
      return;
    }

    const autoSelected = years[years.length - 1].toString();
    const additionalYears = _.range(new Date().getFullYear() - 6, Number(autoSelected));

    const yearOptions = _.uniq(years.concat(additionalYears).sort());
    dispatch(yearSlice.actions.updateYears({ newYears: yearOptions }));
    dispatch(yearSlice.actions.autoselectYear(
      { selectedYear: autoSelected },
    ));
  }
};

export const {
  updateYears,
  updateSelectedYear,
} = yearSlice.actions;
export default yearSlice.reducer;
