import { createMuiTheme, adaptV4Theme } from '@mui/material/styles';

type ChartTheme = {
  fuel: {
    fill: string[];
    getFill: (index: number) => string;
  }
  reddishFuels: {
    fill: string[];
    getFill: (index: number) => string;
  },
  greenishFuels: {
    fill: string[];
    getFill: (index: number) => string;
  },
  blueishFuels: {
    fill: string[];
    getFill: (index: number) => string;
  },
  purpleishFuels: {
    fill: string[];
    getFill: (index: number) => string;
  },
  category: {
    fossil: string;
    renewable: string;
    mixed: string;
    other: string;
  }
};

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    link: string;
  }
  interface PaletteOptions {
    chart: ChartTheme
  }
  interface Palette {
    chart: ChartTheme
  }
}

const theme = createMuiTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 900, // default: 600
      md: 1200, // default: 1056
      lg: 1280, // default: 1280
      xl: 1920, // default: 1920
    },
  },
  palette: {
    primary: {
      main: '#590A99',
    },
    secondary: {
      main: '#FFF7EF',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000',
      link: '#666666',
    },
    chart: {
      fuel: {
        fill:
        [
          '#95abe0',
          '#ffa9a9',
          '#35cbf5',
          '#c2e3ff',
          '#d5b4fb',
          '#6ff9f3',
          '#f4a8df',
          '#bbe199',
          '#01c0d0',
          '#e694a6',
          '#60c39d',
          '#86b78f',
          '#88daa8',
          '#c4ffd1',
          '#ffb8be',
          '#ffaacf',
          '#fe9ebe',
          '#57b6de',
          '#ffd5b7',
          '#d59bb4',
          '#e4ffe5',
          '#d3ceff',
          '#cea28c',
          '#ffd1f1',
          '#ffc5b8',
          '#3bc5b6',
          '#bea2cd',
          '#b8b066',
          '#9fc1ff',
          '#c6ffe5',
        ],
        getFill(i) {
          return this.fill[i % this.fill.length];
        },
      },
      reddishFuels: {
        fill:
        ['#874817',
          '#c18644',
          '#795438',
          '#aa7631',
          '#874726',
          '#94733a',
          '#924b1e',
          '#8f6c4b',
          '#92521d',
          '#c07551',
          '#7a580d',
          '#a5694c',
          '#816123',
          '#ae633e',
          '#814b1a'],
        getFill(i) {
          return this.fill[i % this.fill.length];
        },
      },
      greenishFuels: {
        fill:
        [
          '#84c68b',
          '#8aa154',
          '#b1cd6f',
          '#87e993',
          '#46a44f',
          '#b6e274',
          '#b5d964',
          '#64db81',
          '#46a438',
          '#91e975',
          '#59a22b',
          '#6da016',
          '#00a94a',
          '#8dce4d',
          '#4fd570',
        ],
        getFill(i) {
          return this.fill[i % this.fill.length];
        },
      },
      blueishFuels: {
        fill:
        [
          '#35d8f0',
          '#37a0dc',
          '#50bbf6',
          '#01cbe2',
          '#00cae5',
          '#41e7ff',
          '#65a7ed',
          '#3ae9f2',
          '#2fc8ff',
          '#4cb9ff',
          '#58a4ef',
          '#5db3ff',
          '#00b7f8',
          '#00a1e7',
          '#01a0fa',
        ],
        getFill(i) {
          return this.fill[i % this.fill.length];
        },
      },
      purpleishFuels: {
        fill:
        ['#e4b9a4',
          '#fce3cc',
          '#bb9779',
          '#f1cebd',
          '#ebc09f',
          '#c3ac96',
          '#fecbb1',
          '#b59c84',
          '#f5d4b2',
          '#ba9780',
          '#dbb893',
          '#e9c2af',
          '#c1a487',
          '#e3b7a0',
          '#ffd5b7'],
        getFill(i) {
          return this.fill[i % this.fill.length];
        },
      },
      category: {
        fossil: '#b45f06',
        renewable: '#13ab45',
        mixed: '#95abe0',
        other: '#fce5cd',
      },
    },
  },
  spacing: 9,
  typography: {
    h1: {
      fontFamily: 'Aeonik Bold, Tahoma, Arial, sans-serif',
      fontSize: '3.0rem',
      fontWeight: 500,
      color: '#590A99',
      '@media (max-width:600px)': {
        fontSize: '2.125rem',
      },
    },
    h2: {
      fontFamily: 'Aeonik Bold, Tahoma, Arial, sans-serif',
      fontSize: '2.125rem',
      fontWeight: 500,
      color: '#590A99',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontFamily: 'Aeonik Regular, Tahoma, Arial, sans-serif',
      fontSize: '1.5rem',
      fontWeight: 500,
      color: '#590A99',
      '@media (max-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    h4: {
      fontFamily: 'Aeonik Regular, Tahoma, Arial, sans-serif',
      fontSize: '1.25rem',
      fontWeight: 500,
      color: '#590A99',
      '@media (max-width:600px)': {
        fontSize: '1.125rem',
      },
    },
    h5: {
      fontFamily: 'Aeonik Regular, Tahoma, Arial, sans-serif',
      fontSize: '1.0rem',
      fontWeight: 500,
      color: '#590A99',
    },
    subtitle1: {
      fontFamily: 'Inter Tight, Arial, sans-serif',
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: 'Inter Tight, Arial, sans-serif',
      fontSize: '1.0rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.125rem',
      fontFamily: 'Inter Tight, Arial, sans-serif',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1rem',
      fontFamily: 'Inter Tight, Arial, sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Inter Tight, Arial, sans-serif',
      fontSize: '1.0rem',
      fontWeight: 400,
    },
  },
}));

export default theme;
