import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { RouteComponentProps } from 'react-router-dom';
import { Theme } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      width: '100%',
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const CenteredLayout: FC<RouteComponentProps & { children: React.ReactNode }> = (
  { children },
) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CenteredLayout;
