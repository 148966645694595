import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { AuthConfig, AuthUtilities, authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as urql from '@urql/core';

import {
  Client,
  createClient,
  cacheExchange,
  fetchExchange,
} from 'urql';

let client: Client;

const initializeAuthState = async (): Promise<string> => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return token;
  } catch (e) {
    return '';
  }
};

// TODO: clear token when user logs out
const clientFactory = (): Client => {
  client = createClient({
    fetchOptions: {
      headers: {
        'X-Api-Key': process.env.REACT_APP_GRAPHQL_API_KEY || '',
      },
    },
    url: process.env.REACT_APP_GRAPHQL_API || '',
    maskTypename: true,
    exchanges: [
      cacheExchange,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      authExchange(async (_utils: AuthUtilities) => {
        let token = await initializeAuthState();
        const returnValue: AuthConfig = {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          addAuthToOperation(operation: urql.Operation) {
            if (!token) {
              return operation;
            }

            // fetchOptions can be a function (See Client API),
            //  but you can simplify this based on usage
            const fetchOptions = typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

            const newOperation = urql.makeOperation(operation.kind, operation, {
              ...operation.context,
              fetchOptions: {
                ...fetchOptions,
                headers: {
                  ...fetchOptions.headers,
                  Authorization: token,
                },
              },
            });
            return newOperation;
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          willAuthError(_operation: urql.Operation) {
            if (!token) return true;

            try {
              const decoded = jwt_decode<{
                exp: number,
                auth_time: number,
              }>(token);

              const currentSeconds = Math.floor((new Date()).valueOf() / 1000);
              if (currentSeconds > decoded.exp || currentSeconds < decoded.auth_time) {
                return true;
              }

              return false;
            } catch (error) {
              console.error(error);
              return true;
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          didAuthError(error, _operation) {
            return error.graphQLErrors.some((err) => err.extensions?.code === 'FORBIDDEN');
          },
          async refreshAuth() {
            if (!token) {
              token = await initializeAuthState();
            }
          },
        };
        return returnValue;
      }),
      retryExchange({
        initialDelayMs: 500,
        maxDelayMs: 10000,
        randomDelay: true,
        maxNumberAttempts: 3,
        retryIf: (error) => !!(error.graphQLErrors.length > 0 || error.networkError),
      }),
      fetchExchange,
    ],
  });
  return client;
};

export const useClient = (): Client => client;

export default clientFactory;
