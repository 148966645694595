import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import CompanyTabs from 'Components/Company/CompanyTabs';
import { RouteComponentProps } from 'react-router-dom';
import AlertBox from 'Components/Common/AlertBox';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    height: '100%',
  },
}));

const CompanyWideLayout: FC<RouteComponentProps & { children: React.ReactNode }> = (
  { children, location },
) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CompanyTabs
        location={location}
        maxWidth="xl"
      >
        {children}
      </CompanyTabs>
      <AlertBox />
    </div>
  );
};

export default CompanyWideLayout;
