import React, {
  FC,
} from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material';
import iconPng from '../../logo_blue.png';
import iconLabelFi from '../../logotext_fi.png';
import iconLabelSv from '../../logotext_sv.png';
import iconLabelEn from '../../logotext_en.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: '40px',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  logoImage: {
    maxHeight: '113px',
    maxWidth: '63px',
    width: 'auto',
    height: '100%',
  },
  textPanel: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(0.5),
  },
  spacerTop: {
    flexGrow: 4,
    margin: 0,
    maxWidth: '100%',
    maxHeight: '100%',
  },
  spacerBottom: {
    flexGrow: 6,
    margin: 0,
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const LogoPV: FC = () => {
  const classes = useStyles();
  const { i18n } = useTranslation('main');

  const getSource = () => {
    switch (i18n.language) {
      case ('sv'):
        return iconLabelSv;
      case ('en'):
        return iconLabelEn;
      default:
        return iconLabelFi;
    }
  };

  return (
    <div className={classes.root}>
      <img
        className={classes.logoImage}
        src={iconPng}
        alt="icon"
      />
      <div className={classes.textPanel}>
        <div className={classes.spacerTop} />
        <img
          width={230}
          height={25}
          src={getSource()}
          alt="label"
        />
        <div className={classes.spacerBottom} />
      </div>
    </div>
  );
};

export default LogoPV;