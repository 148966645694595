import { Provider } from 'urql';
import React, { useMemo } from 'react';

import useAuth from 'hooks/useAuth';
import ClientFactory from './client';

const UrqlProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const client = useMemo(() => ClientFactory(), [user]);

  return (
    <Provider value={client}>
      {children}
    </Provider>
  );
};

export default UrqlProvider;
