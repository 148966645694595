import {
  Link,
  AppBar, Container, Tab, Tabs, Toolbar, Typography,
} from '@mui/material';

import LoginButton from 'Components/Common/Login/LoginButton';
import React, {
  FC, ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink, RouteProps,
} from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    height: 'auto', // must be auto so resizes when content resizes
  },
  containerAppBar: {
    padding: 0,
    height: '100%',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(12),
    padding: 0,
    height: '100%',
    width: '100%',
    minHeight: '100vh', // container takes all the viewport height at minimum
    backgroundColor: theme.palette.common.white,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  toolbar: {
    margin: 0,
    padding: 0,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  content: {
    margin: theme.spacing(1),
  },
}));

interface ReportingTabsProps {
  location: RouteProps['location'];
  children?: ReactNode;
}

const ReportingTabs: FC<ReportingTabsProps> = ({ children, location }) => {
  const classes = useStyles();
  const { t } = useTranslation(['main']);
  const allTabs = ['/reporting'];

  const getTabsValue = () => {
    if (location) {
      if (location.pathname.startsWith(allTabs[3])) return allTabs[3];
      return location.pathname;
    }
    return '/';
  };

  return (
    <div
      className={classes.root}
    >
      <AppBar
        position="absolute"
        className={classes.appBar}
      >
        <Container
          maxWidth="lg"
          className={classes.containerAppBar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              variant="h4"
              className={classes.title}
            >
              <Link
                component={NavLink}
                to="/"
                underline="none"
                color="inherit"
              >
                {t('companyForm.navigationBar.title')}
              </Link>
            </Typography>
            <LoginButton />
          </Toolbar>
          <Tabs
            value={getTabsValue()}
            TabIndicatorProps={{ className: classes.indicator }}
          >
            <Tab
              label="Vuosiraportit"
              value={allTabs[0]}
              component={NavLink}
              to={allTabs[0]}
            />
          </Tabs>
        </Container>
      </AppBar>
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <div className={classes.content}>
          {children}
        </div>
      </Container>
    </div>
  );
};

export default ReportingTabs;
