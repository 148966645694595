import React, { FC } from 'react';
import { Container, Theme } from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

import NavTabs from 'Components/Public/NavTabs';
import LoadingScreen from 'Components/LoadingScreen';
import ComparisonWidget from 'Components/Public/Comparison/ComparisonWidget';
import AlertBox from 'Components/Common/AlertBox';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Footer from 'Components/Public/Footer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column', // Use column direction to stack elements vertically
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      //paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.only('sm')]: {
      //paddingTop: theme.spacing(15.5),
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      //paddingTop: theme.spacing(14),
      width: '100%',
    },
  },
}));

const PublicLayout: FC<RouteComponentProps & { children: React.ReactNode }> = (
  { children },
) => {
  const classes = useStyles();
  const { isInitialised } = useAuth();

  if (!isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <NavTabs />
      <Container
        className={classes.content}
        disableGutters
        maxWidth="md"
        >
        <ComparisonWidget />
        <Box mt={4}></Box>
        {children}
      </Container>
      <AlertBox />
      <Footer></Footer>
      </div>
  );
};

export default PublicLayout;
