import {
  AppBar, Container, Link, Tab, Tabs, Theme, Toolbar, Typography,
} from '@mui/material';
import LoginButton from 'Components/Common/Login/LoginButton';
import React, {
  FC, ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink, RouteProps,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    height: 'auto', // must be auto so resizes when content resizes
  },
  containerAppBar: {
    height: '100%',
    width: '100%',
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(14),
    height: '100%',
    width: '100%',
    minHeight: '100vh', // container takes all the viewport height at minimum
    backgroundColor: theme.palette.common.white,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
  },
  toolbar: {
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderBottom: '1px solid lightgray',
  },
  content: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
  },
  languageLink: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.text.link,
    fontFamily: '"Play", sans-serif, Arial',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      display: 'none',
    },
  },
  activeLanguageLink: {
    display: 'none',
  },
  link: {
    color: theme.palette.text.link,
  },
  activeLink: {
  },
}));

interface CompanyTabsProps {
  location: RouteProps['location'];
  children?: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const CompanyTabs: FC<CompanyTabsProps> = ({ children, location, maxWidth }) => {
  const classes = useStyles();
  const { t } = useTranslation(['main']);
  const allTabs = ['/company', '/company/general', '/company/info', '/company/tradesInfo', '/company/preview', '/company/account'];

  const getLocationPathname = (loc: RouteProps['location']) => {
    if (loc) {
      if (loc.pathname === '/company/add/emissions') {
        return '/company/info';
      }
      if (loc.pathname === '/company/add/trades') {
        return '/company/tradesInfo';
      }
      return loc.pathname;
    }
    return '/';
  };

  return (
    <div
      className={classes.root}
    >
      <AppBar
        position="absolute"
        className={classes.appBar}
        elevation={0}
      >
        <Container
          maxWidth="lg"
          className={classes.containerAppBar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              variant="h4"
              className={classes.title}
            >
              <Link
                component={NavLink}
                to="/"
                underline="none"
                color="inherit"
              >
                {t('companyForm.navigationBar.title')}
              </Link>
            </Typography>
            <LoginButton />
          </Toolbar>
          <Tabs
            value={getLocationPathname(location)}
            TabIndicatorProps={{ className: classes.indicator }}
            centered
          >
            <Tab
              label={t('companyForm.navigationBar.frontPage')}
              value={allTabs[0]}
              component={NavLink}
              to={allTabs[0]}
            />
            <Tab
              label={t('companyForm.navigationBar.generalInformation')}
              value={allTabs[1]}
              component={NavLink}
              to={allTabs[1]}
            />
            <Tab
              label={t('companyForm.navigationBar.productionAndEmissionInformation')}
              value={allTabs[2]}
              component={NavLink}
              to={allTabs[2]}
            />
            <Tab
              label={t('companyForm.navigationBar.tradingInformation')}
              value={allTabs[3]}
              component={NavLink}
              to={allTabs[3]}
            />
            <Tab
              label={t('companyForm.navigationBar.preview')}
              value={allTabs[4]}
              component={NavLink}
              to={allTabs[4]}
            />
            <Tab
              label={t('companyForm.navigationBar.user')}
              value={allTabs[5]}
              component={NavLink}
              to={allTabs[5]}
            />
          </Tabs>
        </Container>
      </AppBar>
      <Container
        maxWidth={maxWidth ?? 'lg'}
        className={classes.container}
      >
        <div className={classes.content}>
          {children}
        </div>
      </Container>
    </div>
  );
};

export default CompanyTabs;
