import { LanguageStrings } from 'i18n/fi/main';

const enStrings: LanguageStrings = {
  routes: {
    home: '/en',
    whatCalculator: '/en/whatcalculator',
    background: '/en/background',
    login: '/en/login',
    development: '/en/development',
    news: '/en/news',
    methods: '/en/methods',
    emissions: '/en/emissions',
    wizard: '/en/calculate',
    compare: '/en/compare',
    calculateYourEmissions: '/en/calculateEmissions',
  },
  background: `The emissions calculator is a joint project by Local Power and Finnish district \
  heating companies. It is a transparent and comparable district heat emissions calculator \
  aimed at district heat customers and at operators interested in district heat. The maintenance of the emission calculator was transferred to Finnish Energy at the beginning of 2025.\n\n\
  \
  There are many emissions calculators aimed at consumers and enterprises, but prior to this \
  there has not been a service focusing on district heat emissions.\n\n\
  \
  Previously, calculators based on the average district heat emissions have given the same \
  emissions for district heat regardless of the fuels used. Average emissions have been a \
  simplified way to describe the situation because the district heat production methods vary \
  significantly by locality. Until now, the statistics used in the calculation of emissions \
  have sometimes been updated very late. In addition, the data entered in the statistics has \
  been dispersed and based on different measures (e.g. production plants that are included \
  in the emissions trading scheme and those that are not).\n\n\
  \
  The district heat emissions calculator addresses these shortcomings. It has been \
  programmed to search the carbon dioxide emissions of heat produced for the district \
  heating network of the municipality where the customer lives. The emissions calculator \
  covers about 99 per cent of the district heat data of the entire country. More detailed \
  monthly data is currently entered in it by 75 per cent of all district heating companies \
  operating in Finland, and this the number is rising constantly. Openness in data \
  gathering and transparency in terms of the district heating networks in different \
  municipalities play a key part in the calculator.\n\n\
  \
  Local Power started developing the calculator in spring 2020. The emission calculator was \
  transferred to Finnish Energy on January 1, 2025. Following the transition, the calculator \
  will be open to all district heating companies. This means that any district heating company \
  can request access credentials to the calculator and report their emissions, regardless of \
  whether the company is a member of Finnish Energy or not. Background report of the calculator \
  is available through <2>this link</2>.\n\n\
  \
  The parameters used in the district heat emissions calculator \
  are commensurate, complying with the official methods and parameters of Statistics Finland. \
  The calculator also takes into account emissions data that is based on more precise legislation, \
  such as the emissions trading scheme. Plants involved in the emissions trading scheme measure \
  and verify several matters more accurately than Statistics Finland. These verifications comply \
  with more precise legislation, providing even more accurate calculations of carbon-dioxide \
  emissions from production. The calculator will show whether or not a plant is included in the \
  emissions trading scheme.\n\n\
  \
  Traditionally, the statistics have described emissions retrospectively per calendar year. \
  Companies can enter production data by the \
  month, in which case the end users get more up-to-date information about actual district heat \
  production.\n\n\
  \
  The steering group for the development work of the emissions calculator has included the \
  Finnish Environment Institute, the Association of Finnish Local and Regional Authorities, \
  Statistics Finland, the Energy Authority, Motiva, Finnish Energy, and the Finnish District \
  Heating and Cooling Association FinDHC. Akamon Innovations Oy is responsible for the technical \
  implementation and maintenance of the emissions calculator. The district heat emissions calculator \
  was opened for public use on 11/02/2022. All those developing carbon dioxide emissions calculators ${''}\
  can utilise its data in their own calculators via open interfaces.`,

  development: `The technical expert group of district heating companies is responsible for the \
  development of the district heat emissions calculator. The development work takes into \
  account the development areas highlighted by users of district heat and those needing \
  emissions-related data, needs for change due to legislation, and the needs of district \
  heat producers. The technical expert group consists of experts from different areas of operation.\n\n\
  \
  These include environmental reporting, customer service, sales, technology and production, as well \
  as installation and information systems. Development proposals can be sent to the expert group via \
  <2>this email link</2>. The development work is coordinated by Finnish Energy, with Neea Huttunen as project manager. \
  If you have any questions about the district heat emissions calculator or its development, you ${''}\
  can contact Finnish Energy: neea.huttunen@energia.fi.`,

  whatCalculator: `As its name indicates, the district heat emissions calculator calculates \
  and presents the carbon dioxide emissions of district heat per municipality and per district \
  heating network in an accurate and consistent way. The use of the service is free of charge.\n\n\
  \
  Initial data, i.e. the data of the district heating networks, is entered in the system separately \
  for each network. Every network is given municipal data, on the basis of which the user will \
  receive data concerning their own place of residence. There are district heating networks that \
  are located in the area of several municipalities, and there are municipalities that include several \
  district heating networks. It is useful if the user knows their own energy company and district \
  heating network.\n\n\
  \
  Energy companies enter in the calculator the production mixes used, i.e. fuels and other \
  production methods (e.g. heat produced with heat pumps). The data is entered according to the \
  official and standardised definitions of Statistics Finland. The fuels used are given emission \
  coefficients according to Statistics Finland. If the energy company’s production plant is \
  involved in the EU emissions trading scheme, the measurement methods described in the emission \
  monitoring plan of the emissions permit, such as analysed fuel emission coefficients, shall be used. \
  Only this accurate and measured initial data can be used in place of the coefficients of \
  Statistics Finland. Energy companies can enter in the calculator monthly data according to the \
  actual data for the previous month. The statistical data is around a year old due to the inspection \
  and publication requirements for the statistics.\n\n\
  \
  The calculator includes emission data for the previous five years and the companies’ production \
  mixes for district heat. With the data, the user can calculate the emissions of their own \
  district heat use with the emission coefficient of the latest available full year either by \
  entering their consumption data or by estimating it on the basis of the type, area and construction ${''}\
  year of their house.`,
  methods: {
    content: `The data content of the emissions calculator is based on official data published \
    by the companies themselves.\n\n\
    \
    Emissions data for 2016–2023 has been calculated from statistical publications issued on the \
    basis of data collected annually by Finnish Energy. The data in the district heat statistics \
    has been published by district heat vendors in each municipality. The statistical publications \
    are available on the Finnish Energy website:\n\
    <2>https://energia.fi/en/statistics/statistics-on-district-heating/district-heating-and-cooling/</2>\n\n\
    \
    The specific emission coefficients of fuels used in the calculation have been calculated \
    on the basis of the Fuel Classifications, which are published annually by Statistics Finland. \
    The Fuel Classifications are available on the website of Statistics Finland:\n\
    <6>https://www.stat.fi/tup/khkinv/khkaasut_polttoaineluokitus.html</6>\n\n\
    \
    As the data in Finnish Energy’s statistical publications is presented at the municipal \
    level, the calculation of emission coefficients and emissions made on the basis of it \
    is also presented per district heat vendor for each municipality and production method. \
    The calculation takes into account the district heat sales between vendors in addition \
    to the district heat vendor’s own district heat production. The CO<9>2</9> emissions created in \
    the production of a quantity of district heat sold are calculated as part of the buyer’s \
    emissions on the basis of the average calculated emissions of the vendor’s district heat \
    production.\n\n\
    \
    From the beginning of 2021, district heat vendors have been able to enter directly in \
    the calculator the production data of district heat they have sold with respect to each \
    production plant. The calculator enables entry of data in periods selected by the vendor, \
    e.g. on a monthly basis. District heat sales between vendors to be entered in the calculator \
    include the production data of the sold quantity of district heat. The calculator uses the \
    data entered in the calculator to calculate the emission coefficients and emissions per each \
    plant on the basis of the entered accurate data of district heat sales.\n\n\
    \
    In some cases of the district heat sales between vendors, the heat seller may have entered \
    data into the calculator based on the annual average fuels rather than the fuels directly \
    used for producing the sold district heat. As a result, the emissions calculated based on \
    these fuels may differ from the actual emissions of the district heat batch sold to the \
    district heating company. Consequently, the emissions provided by the emission calculator \
    may differ from the actual emissions of the district heat delivered to the end customers \
    of the district heating company. If the emission data in the calculator differs from the \
    emission data on the district heating company’s own pages, the company’s own emission data \
    is more accurate than the data provided by the calculator for the aforementioned reason.\n\n\
    \
    Hazardous waste (formerly problem waste) is calculated with zero emissions.\n\n\
    ${''}\
    The results calculated per plant and per municipality may differ slightly from each other.`,
    usedCalculationMethods: 'Calculation methods',
  },
  appbar: {
    home: 'Home page',
    whatCalculator: 'What calculator?',
    background: 'Background',
    companies: 'Companies included',
    development: 'Development',
    methods: 'Calculation methods',
    news: 'What’s new',
    company: 'For the company',
    admin: 'Admin',
    login: 'Logging in',
    reporting: 'Reporting',
  },
  pageTitles: {
    home: 'Finnish Energy | District heat emissiong calculator',
    whatCalculator: 'What calculator? - District heat emissiong calculator | Finnish Energy',
    background: 'Background - District heat emission calculator | Finnish Energy',
    development: 'Development - District heat emission calculator | Finnish Energy',
    methods: 'Calculation methods - District heat emission calculator | Finnish Energy',
    results: '{{target}} - District heat CO2 emissions - District heat emission calculator | Finnish Energy',
    wizard: 'Calculate your own emissions - Dirstrict heat emission calculator | Finnish Energy',
    news: 'What’s new - Dirstrict heat emission calculator | Finnish Energy',
  },
  accounts: {
    changePasswordButton: 'Change password',
    changePasswordTitle: 'Change the password for username',
    changePasswordHelper: 'The password must contain at least 10 characters, with at least one upper case letter, one lower case letter, one number and one special character.',
    oldPassword: 'Old password',
    newPassword: 'New password',
    newPasswordAgain: 'New password again',
    errorTextLength: 'Password must contain at least 10 characters',
    errorTextCharacters: 'The password must contain at least one upper case letter, one lower case letter, one number and one special character',
    errorTextNotSame: 'The new passwords must be the same',
    errorTextNotEmpty: 'The field must not be empty',
    errorTextMustDifferFromOld: 'The new password must be different from the old password',
    forgotPassword: 'I forgot my password',
    forgotPasswordTitle: 'We will send you an email with a link for changing the password',
    userDoesNotExist: 'The email address you entered does not have a username',
    wrongUsernameAndOrPassword: 'Incorrect email and/or password',
    code: 'Code',
    username: 'Username',
    password: 'Password',
    passwordChangedSuccesfully: 'Password changed succesfully!',
    returnToLoginPage: 'Return to login page',
    userNotFoundException: 'Changing password failed, user does not exist',
    codeMismatchException: 'Changing password failed, invalid code',
    codeDeliveryPhone: 'Code to change password was delivered to number {{phone}}.',
    codeDeliveryEmail: 'Code to change password was delivered to email {{email}}.',
    login: 'Log in',
    logout: 'Log out',
    email: 'Email address',
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Telephone number',
    companyName: 'Company name',
    title: 'Emissions calculator',
    sendLinkButton: 'Send',
  },
  header: 'Welcome to use your district heat emissions calculator!',

  subHeader: `The district heat emissions calculator shows the carbon dioxide \
  emissions of the selected district heating company or municipality, and you \
  can compare the carbon dioxide emissions of different municipalities and/or ${''}\
  district heating companies and calculate your own carbon dioxide emissions.`,

  subHeaderMore: `The calculator shows the emissions coefficient and emissions of the \
  network-specific district heat production, which may differ from customer-specific \
  district heat production that has been agreed separately with the district heat vendor. \
  It is possible for customers to buy district heat produced with emission-free production ${''}\
  methods from several district heat operators.`,
  searchBar: {
    label: 'Search the district heating company or municipality',
    placeholder: 'You can search with the name of the district heating company or municipality',
    clear: 'Clear',
    loadingText: 'Loading...',
    noResults: 'No results',
    groups: {
      city: 'Municipalities',
      company: 'District heating networks',
    },
  },
  searchBarNetwork: {
    label: 'Search district heating company',
    placeholder: 'You can search with the name of the district heating company',
  },
  searchBarInfo: 'Check the emissions of the district heating company or municipality.',
  buttonInfo: 'If you are a user of district heat, you can also calculate your own emissions.',
  calculateEmissionButton: 'Calculate your own emissions',
  returnHomeButton: 'To home page',
  wizard: {
    locationStep: 'District heating company',
    processStep: 'Initial data',
    inputStep: 'Data entry',
    homeStep: 'Type of house',
    constructionYearStep: 'Year of construction',
    surfaceAceaStep: 'Area',
    consumptionStep: 'Heat consumption',
    consumption: 'Heat consumption per year',
    constructionYear: 'Construction year of house',
    surfaceArea: 'Area of home',
    nextButton: 'Next',
    backButton: 'Back',
    calculateButton: 'calculate your emissions',
    personalResultsTitle: 'Your district heating emission',
    processType: {
      consumption: 'I know how much heat I consume',
      selection: 'I don’t know how much heat I consume',
    },
    homeType: {
      HOUSE: 'Detached house',
      TERRACED_HOUSE: 'Terraced house',
      FLAT: 'Flat',
    },
  },
  result: {
    byCategory: 'By Category',
    byFuels: 'By Fuels',
    headerNetwork: 'Emissions of the district heating network',
    headerArea: 'District heating emissions of the municipality',
    noResultNetwork: 'Calculator has currently no emissions for network {{name}}',
    noResultArea: 'Calculator has currently no emissions for municipality {{name}}',
    subHeader: 'Calculated emissions for year {{year}}',
    updatedAt: 'Last updated {{- date}}',
    fromExcel: 'Emissions have been calculated based on district heating statistics collected by Finnish Energy',
    fromCompany: 'The emissions have been calculated using the data provided by the district heating company',
    emissionFactor: 'Emission coefficient',
    emission: 'Carbon dioxide emissions',
    showInfo: 'Show emissions trend',
    hideInfo: 'Hide emissions trend',
    includeNetworkLosses: 'Network losses are taken into account in the calculation',
    addToComparison: 'Add to comparison',
    calculationMethod: 'Emissions calculation method',
    calculationMethodInfoTitle: 'Further information about calculation methods',
    calculationMethodInfoBody: 'The emissions and emission coefficients of heat production can be examined with several different methods. This calculator has two of the most commonly used methods:\n\nIn the benefit sharing method, the high efficiency achieved with combined heat and power generation is deemed to be beneficial for both electricity and heat production. The benefit sharing method takes into account the efficiency of energy produced with separate electricity and heat production and the amount of energy produced. Fuels and emissions are divided in proportion to the fuel consumption of production methods.\n\nOut of the calculation methods, the energy method is more simple. In the energy method, fuels and emissions are divided in proportion to energies produced. For example, if 70% of total energy produced is heat and 30% is electricity, it is deemed that 70% of fuels have been spent on heat production and 30% of fuels have been spent on electricity production.\n\nFurther information about the methods used is available through <2>this link</2>.',
    fuels: 'Production mix',
    realized: 'Realized',
    planned: 'Planned',
    predictedYear: 'Forecast for the end of the year',
    predictionLegend: 'Forecast',
    calculationMethods: {
      ENERGY: 'Energy method',
      EFFICIENCY: 'Benefit sharing method',
    },
    productionType: 'Näytä tiedot kohteelle',
    productionTypes: {
      DISTRICT_HEATING: 'Kaukolämpö',
      ELECTRICITY: 'Sähkö',
      PROCESS_HEAT: 'Prosessihöyry',
      FUELS: 'Polttoaineet',
      PLANTS: 'Laitokset',
      COMBINED: 'Yhteensä',
    },
    history: {
      emission: 'Emission data {{minYear}}\u2013{{maxYear}}',
      emissionFactor: 'Emission coefficient {{minYear}}\u2013{{maxYear}}',
      totalEmission: 'CO<1>2</1> emissions {{minYear}}\u2013{{maxYear}}',
      fuel: 'Production data {{minYear}}\u2013{{maxYear}}',
    },
  },
  comparison: {
    title: 'Compare emissions',
    caption: 'Add a maximum of three sites to the comparison',
    comparison: 'Comparison of emissions',
    compareButton: 'Compare',
    clearButton: 'Clear all sites',
    emissions: 'Emissions data {{year}}',
    fuels: 'Fuel data {{year}}',
  },
  units: {
    consumption: 'MWh/a',
    emissionFactor: 'kg/MWh',
    emissionFactorCO: 'kgCO\u2082/MWh',
    emissionFactorCOB: 'kgCO<1>2</1>/MWh',
    emissionFactorCompany: 't/TJ',
    districtHeating: 'GWh',
    electricityGeneration: 'GWh',
    fuelConsumption: 'GWh',
    emission: 'kgCO\u2082',
    emissionTon: 'tCO\u2082',
    emissionTonB: 'tCO<1>2</1>',
    surfaceArea: 'm\u00B2',
    power: 'GWh',
    percentage: '%',
  },
  companyForm: {
    preview: {
      publishingSucceeded: 'Publishing succeeded! Updated information will show up in the calculator in a moment',
      hidingSucceeded: 'Hiding emissions succeeded! Emissions will be hidden from the public calculator in a moment',
      showingSucceeded: 'Showing emissions succeeded! Emissions will be shown in the calculator in a moment',
      targetNetwork: 'Network to preview',
      targetYear: 'Target year',
      refresh: 'Refresh',
      publish: 'Publish',
      hide: 'Hide from the calculator',
      show: 'Show in the calculator',
      noEmissions: 'No emissions found',
      noUnsavedData: 'No unsaved data',
    },
    trading: {
      addNewTradesButton: 'Ass new purchase and sale data',
      purchaseInformation: 'Purchase data',
      saleInformation: 'Sale data',
      boughtPower: 'Purchased district heat',
      soldPower: 'Sold district heat',
      boughtPowerInfo: 'Purchased district heat',
      soldPowerInfo: 'Sold district heat',
      usedFuels: 'Fuels used',
      addFuelsRelatedToTrade: 'Add fuels related to trade',
      addNewBoughtFuel: 'Add new purchased fuel',
      addNewSoldFuel: 'Add new sold fuel',
      removeSale: 'Remove sale transaction',
      removePurchase: 'Remove purchase transaction',
      noAddedSales: 'No added sale transactions',
      noAddedPurchases: 'No added purchase transactions',
      addNewPurchase: 'Add new purchase',
      addNewSale: 'Add new sale',
      purchases: 'Purchases',
      sales: 'Sales',
      emissionsEnergyMethodInfo: 'Consumption of sold quantity calculated by the energy method',
      emissionFactorEnergyMethod: 'Emission coefficient (energy method)',
      emissionFactorEnergyMethodInfo: 'Emission coefficient of sold quantity calculated by the energy method',
      emissionsEfficiencyMethodInfo: 'Consumption of sold quantity calculated by the benefit sharing method',
      emissionFactorEfficiencyMethod: 'Emission coefficient (benefit sharing method)',
      emissionFactorEfficiencyMethodInfo: 'Emission coefficient of sold quantity calculated by the benefit sharing method',
      searchBar: {
        labelSale: 'Sold to the company',
        labelPurchase: 'Purchased from the company',
        loadingText: 'Loading... ',
        noResults: 'No results',
        placeholder: 'You can search the district heating company by name',
        clear: 'Clear',
      },
    },
    navigationBar: {
      title: 'Emissions calculator',
      logout: 'Log out',
      frontPage: 'Home page',
      generalInformation: 'Basic data',
      productionAndEmissionInformation: 'Production and emission data',
      tradingInformation: 'TODO',
      preview: 'Preview',
      user: 'Username',
    },
    time: {
      timeSpan: 'Timespan',
      startDate: 'Start dates',
      endDate: 'End date',
      placeholder: 'dd.mm.yyyy',
    },
    company: {
      name: 'Company name',
      date: 'Data valid as from',
      inputDataFromYear: 'Data from',
      dataForYear: 'Data for year',
      dateInfo: 'The data is valid as from the selected start date',
      companyTitle: 'Company’s basic information',
      networksTitle: 'Networks',
      powerPlantsTitle: 'Plants',
      areasTitle: 'Municipalities',
      noAddedNetworks: 'No added networks',
      addYear: 'Add a year',
      month: 'Month',
      timePeriod: 'Time period',
      dataType: 'Information type',
      addNextMonth: 'Add next month',
      alerts: {
        importRealizedYearDataNextYearTitle: 'Vuoden tietojen syöttötapa',
        importRealizedYearDataNextYearContext: 'Syötetäänkö vuoden tiedot kuukausitasolla vai vuositasolla? Vuositason syötössä nykyisen vuoden tiedot voi syöttää myös osittain, jos vuotta on vielä jäljellä.',
        importRealizedYearDataThisYearTitle: 'Vuoden tietojen syöttötapa',
        importRealizedYearDataThisYearContext: 'Syötetäänkö vuoden tiedot kuukausitasolla vai vuositasolla? Vuositason syötössä nykyisen vuoden tiedot voi syöttää myös osittain, jos vuotta on vielä jäljellä.',
        importMonthlyDataTitle: 'Lisää päästötiedot kuukaudelle {{month}}?',
        importMonthlyDataContext: 'Verkkojen tiedot siirretään automaattisesti uudelle kuulle.',
        importFutureDataNextYearTitle: 'Syötä tulevaisuuden ennustetiedot',
        importFutureDataNextYearContext: 'Haluatko syöttää tulevaisuuden ennustetiedot seuraavalle vuodelle? Syötty tapahtuu samalla tavoin kuin toteutuneuden tietojen syötöt.',
        importFutureDataThisYearTitle: 'Syötä tulevaisuuden ennustetiedot',
        importFutureDataThisYearContext: 'Haluatko syöttää tulevaisuuden ennustetiedot tälle vuodelle? Syötty tapahtuu samalla tavoin kuin toteutuneuden tietojen syötöt.',
        labelMonthly: 'Kuukausittain',
        labelYearly: 'Vuosittain',
        labelCancel: 'Peruuta',
        labelYes: 'Kyllä',
        labelAdd: 'Lisää',
      },
    },
    network: {
      name: 'Name of network',
      description: 'Description (public)',
      descriptionInfo: 'Description (public)',
      emissionLossPercentage: 'Loss percentage',
      emissionLossPercentageInfo: 'Information on loss percentage',
      hasEmissionTrading: 'The network is covered by the emissions trading scheme',
      hasNoEmissionTrading: 'The network is not covered by the emissions trading scheme',
      addNetworkButton: 'Add new network',
      removeNetworkButton: 'Remove network',
    },
    networkImportDialog: {
      title: 'Are you sure you want to import networks from previous year?',
      content: 'Networks can be imported from previous year as a base for selected year {{ year }}.',
    },
    areas: {
      area: 'Municipality',
      distributionPercentage: 'Percentage',
      addAreaButton: 'Add new municipality',
      removeAreaButton: 'Remove municipality',
    },
    plant: {
      CHPPlant: 'CHP plant',
      heatingInstallation: 'Heating plant',
      districtHeating: 'Produced district heat',
      electricityGeneration: 'Produced electricity',
      processHeat: 'Produced process steam',
      processHeatInfo: 'Information on produced process steam',
      wasteHeat: 'Waste and excess heat',
      districtHeatingInfo: 'Both produced and recovered heat (for example, produced by pumps) are entered on the "produced heat" line, i.e. they are not separated from each other',
      electricityGenerationInfo: 'Produced electricity',
      wasteHeatInfo: 'Waste and excess heat',
    },
    frontPage: {
      title: 'Welcome!',
    },
    info: {
      addNewInformationButton: 'Add new production and emission data',
      general: {
        title: 'Production and emission data',
        timeSpan: 'Timespan',
        districtHeating: 'Produced district heat (GWh)',
        processHeat: 'Produced process steam (GWh)',
        emissionFactor: 'Emission coefficient (tCO\u2082/TJ)',
        totalEmissions: 'Total emissions (tCO\u2082)',
      },
      table: {
        networkName: 'Network',
        plantName: 'Production plant',
        fuel: 'Fuel',
        totalAmountInGWh: 'Consumption (GWh)',
        fuelEmissionFactor: 'Emission coefficient (tCO\u2082/TJ)',
        processHeat: 'Produced process steam (GWh)',
        districtHeatingGWh: 'Produced district heat (GWh)',
        electricityGenerationGWh: 'Tuotanto sähkö (GWh)',
        emissions: 'Emissions (tCO\u2082)',
        emissionsElectricity: 'Päästöt sähkö (tCO\u2082)',
        emissionsProcessHeat: 'Päästöt prosessihöyry (tCO\u2082)',
        emissionsCombined: 'Päästöt yhteensä (tCO\u2082)',
        emissionFactorDistrictHeating: 'Päästökerroin kaukolämpö (tCO\u2082/TJ)',
        emissionFactorElectricity: 'Päästökerroin sähkö (tCO\u2082/TJ)',
        emissionFactorProcessHeat: 'Päästökerroin prosessihöyry (tCO\u2082/TJ)',
        networksCombined: 'Verkot yhteensä (tCO\u2082)',
      },
    },
    fuel: {
      title: 'Fuels used',
      fuelsInfo: 'Carbon dioxide emissions of BIO-labelled biofuels are not included in the total emissions of Finland’s greenhouse gases, and therefore their emission coefficient must be set as 0',
      fuel: 'Fuel',
      emissionFactor: 'Emission coefficient',
      consumption: 'Consumption',
      addFuel: 'Add fuel',
      removeFuel: 'Remove fuel',
      searchBar: {
        clear: 'Clear',
        loadingText: 'Loading...',
        noResults: 'No results',
      },
    },
    plants: {
      name: 'Name',
      type: 'Plant type',
      CHP: 'CHP',
      other: 'Heating plant',
      districtHeatingGWh: 'District heat production',
      districtHeatingGWhInfoText: 'District heat production',
      electricityGenerationGWh: 'Electricity production',
      electricityGenerationGWhInfoText: 'Electricity production',
      plantDoesntExist: 'Plant does not exist',
      plantHasNoFuels: 'No fuels have been added to the plant',
      addPlantButton: 'Add new plant',
      fuelsButton: 'Fuels...',
      fuels: 'Fuels',
      removePlantButton: 'Remove plant',
      removeFuelButton: 'Remove line',
      addFuelButton: 'Add new fuel',
      emissionFactor: 'Emission coefficient',
      useDefault: 'Enter own figures',
      titleAddPlant: 'Add new plant',
      titleModifyPlant: 'Edit plant',
      searchBar: {
        fuel: 'Fuel',
        loadingText: 'Loading...',
        placeholder: 'Search fuel name',
        noResults: 'No fuels were found with the search phrase',
      },
      table: {
        name: 'Name',
        CHP: 'CHP plant',
        yes: 'Yes',
        no: 'No',
        districtHeatingGWh: 'Heat production (GHw)',
        electricityGenerationGWh: 'Electricity production (GWh)',
        fuels: 'Fuels (Emission coefficient)',
        EditTitle: 'Edit',
        EditInfo: 'Edit plant data',
        RemoveTitle: 'Remove',
        RemoveInfo: 'Remove plant',
        TableEmpty: 'No plants',
      },
    },
    error: {
      percentageNot100: 'Total percentage must be 100%',
      duplicateName: 'Name already exists',
    },
    dateFormatVisible: 'DD.MM.yyyy',
  },
  common: {
    alerts: {
      unsavedChangesTitle: 'Unsaved changes',
      unsavedChangesContentTextLeaveStill: 'Do you still want to leave the page?',
      unsavedChangesContentTextCancelChanges: 'Do you want to delete unsaved changes?',
      saveSuccesfull: 'Saved successfully!',
      fillMissingFields: 'Fill the required fields',
    },
    buttons: {
      save: 'Save',
      back: 'Back',
      leave: 'Leave',
      ok: 'Ok',
      cancel: 'Cancel',
      yes: 'Yes',
      no: 'No',
      import: 'Import',
    },
    dateFormatVisible: 'DD.MM.yyyy',
    dateFormatVisibleHours: 'HH:mm',
    showMore: 'Show more...',
    showLess: 'Show less',
  },
  admin: {
    users: {
      users: 'Usernames',
      addUser: 'Add username',
      editUser: 'Edit username',
      formTitle: 'Create new username for the company',
      userContactInformation: 'User’s contact details',
      companyInformation: 'Company information',
      firstName: 'First name',
      lastName: 'Last name',
      name: 'Name',
      email: 'Email address',
      phone: 'Telephone number',
      company: 'Company',
      userActive: 'Username active',
      active: 'Active',
      noUsers: 'No usernames',
      removeConfirm: 'Are you sure you want to delete the username {{email}}?',
    },
    companies: {
      companies: 'Companies',
      name: 'Name',
      addCompany: 'Add company',
      companyId: 'Business ID',
      removeConfirm: 'Are you sure you want to delete the company {{company}}?',
      editConfirm: 'Are you sure you want to change the company name {{nameOld}} to {{name}}?',
      nameAlreadyExists: 'The name already exists.',
      companyIdAlreadyExists: 'The company ID already exists',
    },
    navigationBar: {
      title: 'Emissions calculator',
      logout: 'Log out',
      frontPage: 'Home page',
      manageUsers: 'User management',
      manageCompanies: 'Company management',
      manageNews: 'News management',
      logs: 'Log data',
    },
    searchBar: {
      label: 'Search district heating company',
      loadingText: 'Loading... ',
      noResults: 'No results',
      placeholder: 'You can search the district heating company by name',
      clear: 'Clear',
    },
    news: {
      editArticle: 'Edit the article',
      removeArticleButton: 'Remove article',
      publishNewArticle: 'Publish new article',
      expiredArticles: 'Expired articles',
      title: 'Title',
      ingress: 'Ingressi',
      category: 'Category',
      author: 'Author',
      subCategory: 'Sub-category',
      expireDate: 'Last day of display',
      articlePicture: 'Article picture',
      articleHasArticlePicture: 'Article includes a picture',
      pictureLink: 'Picture link',
      pictureCaption: 'Caption',
      pictureAlt: 'Alt text',
      articleBodyText: 'Body text of news item',
      showWritingInstructions: 'Show writing instructions',
      failedToFetchArticle: 'Error: Article could not be retrieved from the server',
      inFinnish: 'In Finnish',
      inSwedish: 'In Swedish',
      inEnglish: 'In English',
      preview: 'Preview',
      addArticleButton: 'Publish article',
      editArticleButton: 'Save changes',
      dialogRemove: {
        content: 'Are you sure you want to permanently remove the article from the page?',
        yes: 'Yes',
        no: 'No',
      },
      paragraphInstructions: {
        addNewParagraph: 'Start new paragraph with two consecutive returns',
        addHeader: 'Add title by starting the line "# " (without quotation marks)',
        addImage: 'Add picture by typing ![alt text](link "Picture test")',
        italics: 'Use italics in paragraphs by typing *text* or _text_',
        bold: 'Use bold in paragraphs by typing **text** or __text__',
        leading: 'Use leading text (bold and capital) by typing ***text*** or ___text___',
        addLink: 'Enter text as a link by typing [text](url)',
        asteriskOrUnderscore: 'Enter * by typing \\* and _ by typing \\_',

      },
    },
  },
  errors: {
    fillRequiredFields: 'Fill the required fields',
    invalidPhoneNumber: 'Invalid telephone number',
    invalidEmail: 'Invalid email address',
    networkImportFailed: 'Importing networks failed. If the import was partial, contact administration.',
    failedToSaveToDB: 'Failed to save changes: {{error}}',
    networkError: 'Network error. {{code}}',
    networkErrorNoCode: 'Network error.',
  },
  warnings: {
    noNetworksAddedForYear: 'No networks exist for this year, <2>please add networks from the general tab first</2>.',
    noPlantsAddedForYear: 'No powerplants added for this year, <2>please add plants from the general tab first</2>.',
    noNetworksToPreview: 'No networks to preview, <2>please add networks from the general tab first</2>.',
  },
  errorBoundary: {
    title: 'Something went wrong...',
    subtitle: 'Error occurred in emission calculator. Try to reload page. If reloading does not work return to home.',
    button: 'Reload the page',
  },
  news: {
    photo: 'Picture',
    edited: 'Updated',
    failedToFetchArticleList: 'Error: Retrieval of news items from the server failed.',
    failedToFetchArticle: 'Error: Retrieval of news item from the server failed.',
    noArticlesText: 'This page is empty.',
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    wholeYear: 'Whole year',
  },
};

export default enStrings;
