import { Provider as ReduxProvider } from 'react-redux';
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';

import theme from 'theme';
import store from 'store';
import ErrorView from 'Views/Common/Error';
import UrqlProvider from 'graphql/Provider';
import routes, { RenderRoutes } from 'routes';
import GlobalStyles from 'Components/GlobalStyles';
import { ErrorBoundary } from 'react-error-boundary';

import 'Amplify/configure';
import { AuthProvider } from 'contexts/AuthContext';
import LanguageSetter from 'Components/Common/LanguageSetter';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <UrqlProvider>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <GlobalStyles />
            <AuthProvider>
              <ErrorBoundary FallbackComponent={ErrorView}>
                <LanguageSetter>
                  <RenderRoutes routes={routes} />
                </LanguageSetter>
              </ErrorBoundary>
            </AuthProvider>
          </BrowserRouter>
        </ReduxProvider>
      </UrqlProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
