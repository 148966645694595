import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import publicReducer from 'slices/Public/publicSlice';
import wizardReducer from 'slices/Public/wizardSlice';
import companyReducer from 'slices/Company/companySlice';
import yearReducer from 'slices/Company/yearSlice';
import userReducer from 'slices/Company/userSlice';
import usersReducer from 'slices/Admin/usersSlice';
import companiesReducer from 'slices/Admin/companiesSlice';
import selectedTargetSliceReducer from 'slices/Public/selectedTargetSlice';
import calculationMethodReducer from 'slices/Public/calculationMethodSlice';
import alertReducer from 'slices/Public/alertSlice';
import fuelChartTypeReducer from 'slices/Public/fuelChartTypeSlice';

import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';

const store = configureStore({
  reducer: {
    public: publicReducer,
    wizard: wizardReducer,
    company: companyReducer,
    years: yearReducer,
    companies: companiesReducer,
    user: userReducer,
    users: usersReducer,
    selectedTarget: selectedTargetSliceReducer,
    calculationMethod: calculationMethodReducer,
    fuelChartType: fuelChartTypeReducer,
    alert: alertReducer,
  },
  devTools: !!process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = (): AppDispatch => useReduxDispatch<AppDispatch>();

export default store;
