import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { FuelChartType } from 'types/charts';

export type ChartType = FuelChartType.BYFUEL | FuelChartType.BYCATEGORY; 

type FuelChartTypeState = {
  chartType: ChartType;
};

const initialState:FuelChartTypeState = {
  chartType: FuelChartType.BYCATEGORY,
};

const fuelChartTypeSlice = createSlice({
  name: 'fuelChartType',
  initialState,
  reducers: {
    setFuelChartType(state, action:PayloadAction<{ type: ChartType }>) {
      const { type } = action.payload;
      const stateVar = state;
      if (stateVar.chartType !== type) stateVar.chartType = type;
    },
  },
});

export const fuelChartTypeSelector = (
  state: RootState,
):ChartType => state.fuelChartType.chartType;

export const {
  setFuelChartType,
} = fuelChartTypeSlice.actions;

export default fuelChartTypeSlice.reducer;
