import React, { useEffect } from 'react';
import {
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Automatically set the correct language based on url path
const LanguageSetter = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    // No need to "if" Finnish since it is default language
    if (pathname.length > 3 && pathname.startsWith('/en/') && i18n.language !== 'en') {
      i18n.changeLanguage('en');
    } else if (pathname.length > 3 && pathname.startsWith('/sv/') && i18n.language !== 'sv') {
      i18n.changeLanguage('sv');
    } else if (pathname === '/en' && i18n.language !== 'en') {
      i18n.changeLanguage('en');
    } else if (pathname === '/sv' && i18n.language !== 'sv') {
      i18n.changeLanguage('sv');
    }
  }, [pathname]);

  return (
    <>
      {children}
    </>
  );
};

export default LanguageSetter;
