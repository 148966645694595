import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  Paper,
  Theme,
  Typography,
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useDispatch, useSelector } from 'store';
import { makeStyles } from '@mui/styles';

import {
  removeFromComparison,
  removeAllFromComparison,
  setShowComparison,
} from 'slices/Public/publicSlice';

const useStyles = makeStyles((theme: Theme) => ({
  root: { },
  collapse: {
    padding: theme.spacing(2),
  },
  item: {
    marginLeft: theme.spacing(1),
  },
  link: {
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xl')]: {
      flexDirection: 'column',
    },
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(5),
    },
  },
}));

const ComparisonWidget: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['main']);
  const { comparison, showComparison } = useSelector((state) => state.public);

  return (
    <Paper
      elevation={3}
      className={classes.root}
    >
      <Collapse
        in={comparison.length > 0}
        className={classes.collapse}
        mountOnEnter
        unmountOnExit
      >
        <Typography variant="h3">
          {t('comparison.title')}
          <IconButton
            onClick={() => dispatch(setShowComparison({ show: !showComparison }))}
            size="large"
          >
            {showComparison ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Typography>
        <Collapse in={showComparison}>
          <Typography variant="caption">
            {t('comparison.caption')}
          </Typography>
          <div className={classes.content}>
            <List dense>
              {comparison.map((c) => (
                <ListItem key={c.id}>
                  <HighlightOffIcon
                    fontSize="small"
                    onClick={() => dispatch(removeFromComparison({ id: c.id }))}
                  />
                  <Typography
                    variant="h4"
                    className={classes.item}
                  >
                    <span
                      onClick={() => {
                        if (c.type === 'area') {
                          history.push(`/paastot/${c.name}`);
                        } else {
                          history.push(`/paastot/${c.name}/${c.id}/${c.companyId || ''}`);
                        }
                      }}
                      className={classes.link}
                    >
                      {c.name}
                    </span>
                  </Typography>
                </ListItem>
              ))}
            </List>
            <div className={classes.buttons}>
              <Button
                onClick={() => history.push('/vertaile')}
                disabled={comparison.length < 2}
                variant="contained"
                color="primary"
              >
                {t('comparison.compareButton')}
              </Button>
              <Button
                onClick={() => dispatch(removeAllFromComparison())}
                disabled={comparison.length === 0}
                size="small"
                variant="text"
                color="primary"
              >
                {t('comparison.clearButton')}
              </Button>
            </div>
          </div>
        </Collapse>
      </Collapse>
    </Paper>
  );
};

export default ComparisonWidget;
