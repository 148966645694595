import { Alert } from '@mui/lab';
import React, {
  FC,
} from 'react';
import { RootState, useDispatch, useSelector } from 'store';
import { hideAlert } from 'slices/Public/alertSlice';
import { Snackbar } from '@mui/material';

/**
 * Generic alert box for showing timed alerts on the bottom of the screen.
 * Uses redux state so it can easily be used everywhere on the site.
 * @returns Alert within snackbar
 */
const AlertBox: FC = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state: RootState) => state.alert);

  const handleClose = () => {
    dispatch(hideAlert());
  };

  return (
    <Snackbar
      open={alert.enabled}
      autoHideDuration={alert.timeMs}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={alert.severity}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertBox;
