/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Container, Typography, AppBar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import Logo from 'Components/Common/Logo';
import LogoPV from 'Components/Common/LogoPV';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 0),
    position: 'relative',
    bottom: 0,
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

const Footer: FC = () => {
  const { t } = useTranslation(['main']);
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Container
        className={classes.content}
        disableGutters
        maxWidth="md"
        >
          <Box display="flex"
            marginLeft={1}
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent="flex-start"
            alignItems="flex-start">
              <Box 
                marginRight={4}
                marginBottom={'10px'}
              >
                <Logo 
                  width={215}
                  height={50.6833}/>
              </Box>
              <Box height={'40px'}
                marginTop={'15px'}>
                <LogoPV />
              </Box>
          </Box>
      </Container>
    </div>
  );
};

export default Footer;