export enum CalculationMethod {
  UNKNOWN = 'UNKNOWN',
  ENERGY = 'ENERGY',
  EFFICIENCY = 'EFFICIENCY',
}

export enum CalculationTarget {
  CITY = 'CITY',
  GRID = 'GRID',
}

export interface Fuel {
  code: string;
  name: string;
  nameSv: string;
  nameEn: string;
  consumption: number;
}

export interface CalculationResult {
  targetId?: string;
  target: string;
  year: number,
  updatedAt?: string,
  production: number;
  emissionFactor: number;
  totalEmission: number;
  totalFuelConsumption: number;
  isFromHistory?: boolean;
  isPrediction?: boolean;
  calculationMethod: CalculationMethod;
  fuels: Fuel[];
}

export interface HistoryResult {
  target: string;
  history: CalculationResult[];
}

export type TargetType = 'area' | 'network';
