import React from 'react';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';

import 'moment/locale/fi';
import 'i18n/config';
import './fonts.css';

import App from './App';

// Test comment

render(
  <App />,
  document.getElementById('root'),
);
